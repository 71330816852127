import type { SettingsCode, SettingsState } from "~/types";
import { settingsCodes } from "~/types";

type Properties = {
    fetched: boolean;
    settings: any;
};
type Methods = Pick<SettingsState, "get" | "fetch">;

export const useSettingsStore = defineStore<
    "settings",
    Properties,
    {},
    Methods
>("settings", {
    state() {
        return {
            fetched: false,
            settings: {},
        };
    },

    actions: {
        async fetch() {
            if (this.fetched) {
                return;
            }
            const res = await useFetchWithCredentials("/api/v1/settings", {
                key: "settings",
                query: mapObjectToQuery({
                    code: settingsCodes,
                }),
            });
            if (res.status.value === "success") {
                this.settings = res.data.value;
                this.fetched = true;
            }
        },
        get(code) {
            return this.settings?.[code] ?? null;
        },
    },
});
