export const settingsCodes = [
    "politika-konfidentsialnosti",
    "polzovatelskoe-soglasenie",
    "social_network",
    "soglasie",
    "main_phone",
    "main_email",
    "msk_phone",
    "spb_phone",
    "main_video",
] as const;

export type SettingsCode = (typeof settingsCodes)[number];

export interface Settings {
    ["politika-konfidentsialnosti"]: [string];
    ["polzovatelskoe-soglasenie"]: [string];
    ["social_network"]: Record<"telegram" | "youtube" | "vk", any>[];
    ["soglasie"]: [string];
    ["main_phone"]: [string];
    ["main_email"]: [string];
    ["msk_phone"]: [string];
    ["spb_phone"]: [string];
    ["main_video"]: { link: string; text: string };
}

export interface SettingsState {
    fetch(): Promise<void>;
    get<Code extends SettingsCode>(code: Code): Settings[Code] | null;
}
