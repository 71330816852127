export function mapObjectToQuery(obj: object): Record<string, any> {
    const result: any = {};

    function f(prefix: string | null, obj: any) {
        if (typeof obj !== "object" || obj === null || obj === undefined) {
            if (!prefix) return;
            result[prefix] = obj;
            return;
        }

        if (Array.isArray(obj))
            (obj as any[]).forEach((value, i) => {
                const localPrefix = prefix ? `${prefix}[${i}]` : `[${i}]`;
                f(localPrefix, value);
            });
        else
            Object.keys(obj).forEach((key) => {
                const localPrefix = prefix ? `${prefix}[${key}]` : key;
                f(localPrefix, obj[key]);
            });
    }
    f(null, obj);

    return result;
}
